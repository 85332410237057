<h3>How to use MyToolbox?</h3>
<p>
  The videos below can help you to understand how to use the Toolbox application
</p>
<div class="videos">
  <mt-card-video
    videoUrl="https://powered-by.qbank.se/atlascopco/myatlascopco/ae3de1a9d0852745a3596df17507f718.mp4?v=20240515135129"
    title="How to use the search"
  ></mt-card-video>
  <mt-card-video
    videoUrl="https://powered-by.qbank.se/atlascopco/myatlascopco/055ab258eb28834f95103bae5b675761.mp4?v=20240515135129"
    title="How to save a search"
  ></mt-card-video>
  <mt-card-video
    videoUrl="https://powered-by.qbank.se/atlascopco/myatlascopco/2165cb85438d7f08e698d38cbaad2476.mp4?v=20240515135129"
    title="How to use the upload"
  ></mt-card-video>
</div>
