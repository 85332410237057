<h3>Contact us</h3>
<p class="help-text">
  If you have need any help or have any questions or comments, please reach out.
</p>
<section class="cards">
  <mt-card
    *roles="rolesInternal"
    image="./assets/help.png"
    text="Get in touch with our Toolbox support team and report your feedback <a href='mailto:pt.cx@support.atlascopco.com'>pt.cx@support.atlascopco.com</a>"
    title="Need of help or support?"
  />
  <mt-card
    *roles="rolesExternal"
    image="./assets/help.png"
    title="Need of help or support?"
    text="Get in touch with your local customer center and report your feedback"
  />
</section>
