<h3>FAQ</h3>
<h4>Search</h4>
<section class="faq-items">
  <mt-details summary="How to perform a basic search in MyToolbox?">
    <h4>Use the Search Bar and Dropdown Filters:</h4>
    <ul>
      <li>Enter your keywords in the search bar (with or without quotation marks)</li>
      <li>Use the dropdown filters underneath to refine your search.</li>
    </ul>
    <p>Examples:</p>

    <ul>
      <li>
        <span class="bold">Specific search:</span> You're looking for an English brochure QAS 200.
        Type in the search bar <span class="bold">“QAS 200”</span> (use quotation marks) <br />
        Select content type “Instruction Book” and Language “English”
      </li>
    </ul>
    <img src="./assets/faq/basic_search_v3.png" alt="Basic search" />
    <ul>
      <li>
        <span class="bold">Generic search:</span> You are looking for all QAS Instruction books Type
        in the search bar QAS (without quotation marks) Select content type “Instruction Book”
      </li>
    </ul>
    <p>
      Note: if you type QAS 200, the system will look for all documents tagged with QAS and 200, not
      the combination of both. This gives you the option to search as open as possible.
    </p>
    <img src="./assets/faq/basic_search_2_v3.png" alt="Basic search" />
    <ul>
      <li>
        You can <span class="bold">further narrow your initial search</span> by scrolling down to
        the left pane, add filters and click “Show results”. The left pane will show you the amount
        of documents related to QAS and Instruction Book as content, with a detailed count per
        property. The tag with the highest volume appears above.
      </li>
    </ul>
    <img src="./assets/faq/basic_search_filters.png" alt="Basic search filters" />

    <ul>
      <li>Default search is only looking for Active documents. If necessary, adapt.</li>
      <li>
        The default setting will sort by relevance.The top results will be English brochures about
        QAS 200. As you scroll down, results may become less relevant, showing brochures about QAS
        in general or just English brochures.
      </li>
    </ul>
    <p>
      <span class="bold">Note:</span> All filters work in an AND relationship between each other,
      and in an OR relationship within the same filter
    </p>
  </mt-details>
  <mt-details summary="How to get specific search results in MyToolbox?">
    <h4>Refine your search using the Left Panel or using Quotation marks:</h4>
    <ul>
      <li>
        Use the left panel to further specify your search criteria. For instance, selecting "QAS
        200" and "EN" (English) will narrow the results to documents that are both about QAS 200 and
        in English. <span class="bold">The left panel filters</span> have an
        <span class="bold">AND relationship</span>, ensuring all criteria are met. Click on “Show
        results” to confirm.
      </li>
    </ul>

    <img src="./assets/faq/specific_search_v3.png" alt="Specific search" />
    <ul>
      <li>
        Place "QAS 200" in <span class="bold">quotation marks</span> in the free text search. This
        ensures that the <span class="bold">exact phrase</span> is tagged or present in the
        documents.
      </li>
    </ul>
    <img src="./assets/faq/specific_search_2_v3.png" alt="Specific search" />
  </mt-details>
  <mt-details summary="How to search for Archived assets?">
    <p>When you run a search, only "active" documents are shown by default.</p>
    <h4>To see "archived" documents</h4>
    <ol>
      <li>Uncheck the "active" checkbox.</li>
      <li>Click "find the right asset" to rerun the search.</li>
    </ol>
    <p>
      You can choose whether you want to display both active and archived documents or only active
      or only archived documents.
    </p>

    <img src="./assets/faq/search_archived.png" alt="Status facet" />
    <p class="bold">
      To filter specifically for active or archived documents after results are shown.
    </p>
    <ul>
      <li>
        Check the corresponding box and rerun the search in the left pane. Check the checkbox and
        click “show results”.
      </li>
    </ul>
    <p>
      Note: Obsolete documents will not appear in search results and are not visible on MyToolbox.
    </p>
    <img src="./assets/faq/search_archived_facets.png" alt="Status facet" />
  </mt-details>
  <mt-details summary="Why didn't I get any results for a specific Content Type?">
    <p>Some content types do not have documents yet, so this is normal.</p>
  </mt-details>

  <!-- <mt-details summary="What does the 'Relevance' Filter imply when searching for a document?">
    <p>
      The "relevance" filter prioritizes search results based on certain tags that have higher
      weightings. This means that documents matching these tags will appear higher in the search
      results.
    </p>
    <ul>
      <li>
        <span class="bold">High Relevance Tags</span>: Country, Content Type, Category, Language
      </li>
      <li>
        <span class="bold">Very High Relevance Tags</span>: SB Number, Document Number (if filled)
      </li>
    </ul>
  </mt-details> -->

  <mt-details summary="Best practices for getting Better Search Results using the Search Bar">
    <p>MyToolbox offers various operators to refine search results.</p>
    <p>
      Using the document with the title <span class="bold">“Electric Compressor Pump”</span>. Words
      are automatically reduced to their basic forms for improved accuracy (e.g., "Pumps" becomes
      "Pump").
    </p>
    <p>The table below describes the different ways to search for the document.</p>
    <img src="./assets/faq/best_practices_table.png" alt="best practices table" />
  </mt-details>

  <mt-details summary="What is shown on the Asset Card?">
    <p>
      The first line of the asset card displays the content type, the state, and the creation date
      of the asset.
    </p>
    <p>The second line is the title of the asset</p>
    <p>
      The third line contains oval boxes that indicate the tagged “Model”, “Range”, “Language”,
      “Product company”, “Asset Audience“ and “Visibility“ of the asset. <br />
      The latter 2 are only visible for internal users.
    </p>
    <p>
      The bottom of the asset card gives the user the options to download, view, share or see the
      details of the asset.
    </p>
    <img src="./assets/faq/asset_card_v2.png" alt="asset card" />
  </mt-details>
  <mt-details summary="Why are certain file names different from the title in MyToolbox?">
    <p>
      The system contains legacy files. Therefore, it was decided to change only the title, not the
      file name, to avoid synchronization errors with other applications.
    </p>
  </mt-details>
</section>
<h4>Subscription</h4>
<section class="faq-items">
  <mt-details summary="How do I Subscribe in MyToolbox?">
    <p>
      Subscription functionality in MyToolbox is now integrated with the “Saved Search” feature.
      Follow these steps to subscribe:
    </p>
    <ol>
      <li class="bold">Create and Save a Search:</li>
      <ul>
        <li>
          Perform a search using the free text search, dropdown filters, and left-hand pane filters.
        </li>
        <li>Click the “Save Search” button to save your search query.</li>
      </ul>
      <li class="bold">Subscribe to Document Changes:</li>
      <ul>
        <li>
          After saving your search, you can subscribe to receive updates for document changes on a
          regular interval (daily, weekly, or monthly).
        </li>
        <li>
          You can unsubscribe or subscribe on your saved search later using the “Edit” functionality
          in your subscriptions page.
        </li>
      </ul>
    </ol>
    <p>
      <span class="bold">Note:</span> A MyToolbox administrator can also subscribe internal users to
      a search they created. If you're not interested, you can remove these subscriptions yourself.
      To resubscribe, you will need assistance from an administrator.
    </p>
    <img src="./assets/faq/how_to_subscribe_v3.png" alt="How to subscribe" />
  </mt-details>
  <mt-details summary="How can I adapt my existing Saved Search filters in MyToolbox?">
    <p>
      Currently, MyToolbox does not support direct editing of Saved Search filters. However, you can
      use the following workaround:
    </p>
    <ol>
      <li>Click on the name of your "Saved Search" to execute the search.</li>
      <li>Add or remove filters as needed.</li>
      <li>Save these changes as a new "Saved Search".</li>
    </ol>
  </mt-details>
  <mt-details summary="Sharing and restrictions">
    <p>
      When sharing the link to an asset, the link will direct to the details page of the asset. This
      ensures that individuals clicking on the link must possess the appropriate rights to view it.
      The same principle applies to the link included in the subscription emails.
    </p>
    <img src="./assets/faq/sharing_and_restrictions.png" alt="Sharing and restrictions" />
  </mt-details>
</section>
