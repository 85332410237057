import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardVideoUiComponent } from '@my-toolbox-fe/ui-elements';

@Component({
  selector: 'mt-how-to-use',
  standalone: true,
  imports: [CommonModule, CardVideoUiComponent],
  templateUrl: './how-to-use.ui-component.html',
  styleUrl: './how-to-use.ui-component.scss',
})
export class HowToUseUiComponent {}
