import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ROLE_UPLOADER_GLOBAL,
  ROLE_UPLOADER_LOCAL,
  RolesDirective,
} from '@my-toolbox-fe/feat-roles';
import { ContactUiComponent } from '../../ui-components/contact/contact.ui-component';
import { FaqUiComponent } from '../../ui-components/faq/faq.ui-component';
import { HowToUseUiComponent } from '../../ui-components/how-to-use/how-to-use.ui-component';

import { environment } from '@my-toolbox-fe/environments/environment';
import { CardVideoUiComponent } from '@my-toolbox-fe/ui-elements';
import { UploadHistorySmartComponent } from '@my-toolbox-fe/feat-upload';

@Component({
  selector: 'mt-tabs',
  standalone: true,
  imports: [
    CommonModule,
    UploadHistorySmartComponent,
    RolesDirective,
    FaqUiComponent,
    ContactUiComponent,
    CardVideoUiComponent,
    HowToUseUiComponent,
  ],
  templateUrl: './tabs.ui-component.html',
  styleUrl: './tabs.ui-component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TabsUiComponent {
  upload = [ROLE_UPLOADER_GLOBAL, ROLE_UPLOADER_LOCAL];
  protected readonly environment = environment;
}
