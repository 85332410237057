<sl-tab-group>
  <sl-tab panel="howToUse" slot="nav"> How to use MyToolbox</sl-tab>
  <sl-tab active panel="faq" slot="nav"> FAQ </sl-tab>
  <sl-tab panel="contact" slot="nav"> Contact </sl-tab>

  <sl-tab-panel name="howToUse">
    <mt-how-to-use></mt-how-to-use>
  </sl-tab-panel>
  <sl-tab-panel name="faq">
    <mt-faq />
  </sl-tab-panel>
  <sl-tab-panel name="contact">
    <mt-contact />
  </sl-tab-panel>
</sl-tab-group>
