import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsUiComponent } from '@my-toolbox-fe/ui-elements';

@Component({
  selector: 'mt-faq',
  standalone: true,
  imports: [CommonModule, DetailsUiComponent],
  templateUrl: './faq.ui-component.html',
  styleUrl: './faq.ui-component.scss',
})
export class FaqUiComponent {}
