import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardUiComponent } from '@my-toolbox-fe/ui-elements';
import {
  ROLE_EXTERNAL,
  ROLE_INTERNAL,
  RolesDirective,
} from '@my-toolbox-fe/feat-roles';

@Component({
  selector: 'mt-contact',
  standalone: true,
  imports: [CommonModule, CardUiComponent, RolesDirective],
  templateUrl: './contact.ui-component.html',
  styleUrl: './contact.ui-component.scss',
})
export class ContactUiComponent {
  rolesInternal = [ROLE_INTERNAL];
  rolesExternal = [ROLE_EXTERNAL];
}
